
const CloseButton = () => import('~/components/Button/CloseButton.vue')
const GvButton = () => import('~/components/Button/GvButton.vue')

export default {
  components: { CloseButton, GvButton },
  props: ['visible', 'onClose'],
  data() {
    return {
      firstContent: true,
      googlePlayLink: 'https://play.google.com/store/apps/details?id=com.ganknow.app&hl=en&gl=US',
      appStoreLink: 'https://apps.apple.com/sg/app/creator-companion-app/id6446162254',
    }
  },
  methods: {
    downloadApp(type) {
      if (type === 'android') {
        window.open(this.googlePlayLink, '_blank')
      } else {
        window.open(this.appStoreLink, '_blank')
      }
      this.$emit('onClose')
    },
    closeDialog() {
      if (this.firstContent) {
        this.firstContent = false
      } else {
        this.$emit('onClose')
      }
    },
  },
}
